import { ceil } from "lodash";
import { ReactNode, useRef, useMemo } from "react";
import { useTable, Column, usePagination } from "react-table";

import styles from "./Table.module.css";

interface TableProps {
  data: any[];
  columns: Column<any>[];
  onClickRow?: (value: any) => void;
  onChangePageNum?: (page: number) => void;
  onChangePageSize?: (pageSize: number) => void;
  totalCount: number;
}

const Table = ({
  columns,
  data,
  onClickRow,
  onChangePageNum,
  onChangePageSize,
  totalCount,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    usePagination
  );
  return (
    <div className={styles.tableWrapper}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={i}
              className={styles.headerGroupRow}
            >
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps()}
                  key={i}
                  className={styles.headerGroupCell}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={i}
                className={onClickRow ? styles.dataRowPointer : styles.dataRow}
                onClick={() => {
                  onClickRow && onClickRow(row.original);
                }}
              >
                {row.cells.map((cell: any, i: number) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={i}
                      className={styles.dataCell}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
        <span style={{ height: "100%", paddingRight: "10px" }} />
        <span>
          <span>{`Page ${pageIndex + 1} of ${pageOptions.length}`}</span>
        </span>
        <span style={{ height: "100%", paddingRight: "5px" }} />
        <span>
          {`| Go to page: `}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={ceil(totalCount / pageSize)}
            onChange={(e) => {
              console.log(e.target.value);
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
              onChangePageNum && onChangePageNum(page);
            }}
            style={{ width: "100px" }}
            value={pageIndex + 1}
          />
        </span>
        <span style={{ height: "100%", paddingRight: "5px" }} />
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            onChangePageSize && onChangePageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
export { Table };
