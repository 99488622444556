import { useEffect } from "@storybook/addons";
import { ReactNode, useRef, createRef } from "react";
import ReactPlayer from "react-player";

import styles from "./Video.module.css";

interface VideoProps {
  src: string;
  aspectRatio: string;
  playsInline: boolean;
  autoPlay: boolean;
  muted: boolean;
  playbackRatio?: number;
  loop: boolean;
  controls?: boolean;
  onEnded?: (event: React.MouseEvent<HTMLVideoElement>) => void;
}

const Video = ({
  src,
  aspectRatio,
  playsInline,
  autoPlay,
  muted,
  playbackRatio,
  loop,
  controls = true,
  onEnded,
}: VideoProps) => {
  // const videoRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<ReactPlayer>(null);
  return (
    <div
      className={
        aspectRatio === "16/9" ? styles.videoWrapper169 : styles.videoWrapper43
      }
    >
      <ReactPlayer
        url={src}
        width="100%"
        height="100%"
        playsinline={playsInline}
        muted={muted}
        playing={autoPlay}
        controls={controls}
        loop={loop}
        onEnded={() => {
          onEnded && onEnded({} as React.MouseEvent<HTMLVideoElement>);
        }}
        ref={videoRef}
        playbackRate={playbackRatio ?? 1.0}
      />
    </div>
  );
};
export { Video };
