import fetch from "isomorphic-unfetch";
import {
  addInterviewAnswerLogRequest,
  submitPostQuestionAnswerRequest,
} from "../mocks/data/experiment";
import {
  CreateExperimentRequest,
  SubmitAgreementRequest,
  SubmitProfileRequest,
  SubmitPreQuestionAnswerRequest,
  AddInterviewQuestionLogRequest,
  AddInterviewAnswerLogRequest,
  SubmitPostQuestionAnswerRequest,
  SubmitInterviewFeedbacksRequest,
  SubmitDebriefingRequest,
} from "../types/models";

import { toSnakeJSON, toCamelJSON } from "../utils/caseConverter";
import {
  caseConverSubmitDebriefingRequest,
  caseConverSubmitInterviewFeedbacksRequest,
  caseConvertAddInterviewAnswerLogRequest,
  caseConvertAddInterviewQuestionLogRequest,
  caseConvertCreateExperimentRequest,
  caseConvertSubmitAgreementRequest,
  caseConvertSubmitPostQuestionAnswerRequest,
  caseConvertSubmitPreQuestionAnswerRequest,
  caseConvertSubmitProfileRequest,
} from "../utils/requestCaseConverter";
import {
  caseConvertAddInterviewAnswerResponse,
  caseConvertAddInterviewQuestionResponse,
  caseConvertExperimentResponse,
  caseConvertGetInterviewFeedbacksResponse,
  caseConvertSubmitExperimentResponse,
  caseConvertSubmitInterviewFeedbackResponse,
  caseConvertSubmitInterviewResponse,
  caseConvertUnlockPageResponse,
} from "../utils/responseCaseConverter";

export const unlockPage = async (passcode: string) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/unlock-page`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ passcode: passcode }),
    }
  );

  return {
    data: caseConvertUnlockPageResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const createAIExperiment = async (request: CreateExperimentRequest) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-experiment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertCreateExperimentRequest(request)),
    }
  );

  return {
    data: caseConvertExperimentResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const startAIExperiment = async (experimentId: number) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-experiment/${experimentId}/start`,
    {
      method: "PUT",
    }
  );

  return {
    data: caseConvertExperimentResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const submitAgreement = async (
  experimentId: number,
  request: SubmitAgreementRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-experiment/${experimentId}/agreement`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertSubmitAgreementRequest(request)),
    }
  );

  return {
    data: caseConvertSubmitExperimentResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const submitProfile = async (
  experimentId: number,
  request: SubmitProfileRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-experiment/${experimentId}/profile`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertSubmitProfileRequest(request)),
    }
  );

  return {
    data: caseConvertSubmitExperimentResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const startAIInterview = async (interviewId: number) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-interview/${interviewId}/start`,
    {
      method: "PUT",
    }
  );

  return {
    data: caseConvertSubmitInterviewResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const submitPreQuestionAnswer = async (
  interviewId: number,
  request: SubmitPreQuestionAnswerRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-interview/${interviewId}/preq-answer`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertSubmitPreQuestionAnswerRequest(request)),
    }
  );

  return {
    data: caseConvertSubmitInterviewResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const addInterviewQuestion = async (
  request: AddInterviewQuestionLogRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/interview-question`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertAddInterviewQuestionLogRequest(request)),
    }
  );

  return {
    data: caseConvertAddInterviewQuestionResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const addInterviewAnswer = async (
  request: AddInterviewAnswerLogRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/interview-answer`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertAddInterviewAnswerLogRequest(request)),
    }
  );

  return {
    data: caseConvertAddInterviewAnswerResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const submitPostQuestionAnswer = async (
  interviewId: number,
  request: SubmitPostQuestionAnswerRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-interview/${interviewId}/postq-answer`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConvertSubmitPostQuestionAnswerRequest(request)),
    }
  );

  return {
    data: caseConvertSubmitInterviewResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const submitInterviewFeedbacks = async (
  interviewId: number,
  request: SubmitInterviewFeedbacksRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-interview/${interviewId}/feedbacks`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConverSubmitInterviewFeedbacksRequest(request)),
    }
  );

  return {
    data: caseConvertSubmitInterviewFeedbackResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const finishAIInterview = async (interviewId: number) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-interview/${interviewId}/finish`,
    {
      method: "PUT",
    }
  );

  return {
    data: caseConvertSubmitInterviewResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const submitDebriefing = async (
  experimentId: number,
  request: SubmitDebriefingRequest
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-experiment/${experimentId}/debriefing`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(caseConverSubmitDebriefingRequest(request)),
    }
  );

  return {
    data: caseConvertExperimentResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const finishAIExperiment = async (experimentId: number) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/ai-experiment/${experimentId}/finish`,
    {
      method: "PUT",
    }
  );

  return {
    data: caseConvertExperimentResponse(await response.json()),
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const getExperimentSheet = async (
  language: string,
  startId: number,
  endId: number
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/experiment-sheet?language=${language}&start_id=${startId}&end_id=${endId}`,
    {
      method: "GET",
    }
  );

  return {
    data: response,
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const getAnalysisSheet = async (
  language: string,
  startId: number,
  endId: number
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/analysis-sheet?language=${language}&start_id=${startId}&end_id=${endId}`,
    {
      method: "GET",
    }
  );

  return {
    data: response,
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const getInterviewSheet = async (
  language: string,
  startId: number,
  endId: number
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/interview-sheet?language=${language}&start_id=${startId}&end_id=${endId}`,
    {
      method: "GET",
    }
  );

  return {
    data: response,
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const getPersonalExperimentSheet = async (
  language: string,
  interviewer_name: string,
  experimentId: number
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/experiment-sheet/${experimentId}?interviewer_name=${interviewer_name}&language=${language}`,
    {
      method: "GET",
    }
  );

  return {
    data: response,
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};

export const getPersonalInterviewSheet = async (
  language: string,
  interviewer_name: string,
  experimentId: number
) => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AVATARTRAINING_AI_API_DOMAIN}/avatartraining/interview-sheet/${experimentId}?interviewer_name=${interviewer_name}&language=${language}`,
    {
      method: "GET",
    }
  );

  return {
    data: response,
    status: response.status,
    statusText: response.statusText,
    isError: !response.ok,
  };
};
