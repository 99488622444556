import {
  AddInterviewAnswerResponse,
  AddInterviewQuestionResponse,
  Avatar,
  AvatarResponse,
  Experiment,
  ExperimentResponse,
  GetExperimentListResponse,
  GetInterviewFeedbacksResponse,
  GetInterviewQuestionAnswerListResponse,
  GetSettingListResponse,
  GetVideoResponse,
  GetTrainingResultResponse,
  GetVideoExampleResponse,
  GetVideoIdleResponse,
  Interview,
  InterviewAnswer,
  InterviewFeedback,
  InterviewQuestion,
  InterviewQuestionAnswer,
  PredictionResponse,
  Profile,
  ResponsePredictionResponse,
  Setting,
  SettingResponse,
  SpeechToTextResponse,
  SubmitExperimentResponse,
  SubmitInterviewFeedbackResponse,
  SubmitInterviewResponse,
  AvatarVideoResponse,
  AvatarVideoListResponse,
  AvatarKeywordListResponse,
} from "../types";
import { toCamelJSON, toSnakeJSON } from "./caseConverter";

// Avatar Training API

export const caseConvertSetting = (setting: Setting | any) => {
  return toCamelJSON(setting);
};

export const caseConvertProfile = (profile: Profile | any) => {
  return toCamelJSON(profile);
};

export const caseConvertGetSettingListResponse = (
  response: GetSettingListResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.settings) {
    for (let i = 0; i < data.settings.length; i++) {
      data.settings[i] = caseConvertSetting(data.settings[i]);
    }
  }
  return data;
};

export const caseConvertSettingResponse = (response: SettingResponse | any) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.setting) {
    data.setting = caseConvertSetting(data.setting);
  }
  return data;
};

export const caseConvertGetExperimentListResponse = (
  response: GetExperimentListResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.experiments) {
    for (let i = 0; i < data.experiments.length; i++) {
      data.experiments[i] = caseConvertExperiment(data.experiments[i]);
      if (data.experiments[i].profile) {
        data.experiments[i].profile = caseConvertProfile(
          data.experiments[i].profile
        );
      }
      if (data.experiments[i].setting) {
        data.experiments[i].setting = caseConvertSetting(
          data.experiments[i].setting
        );
      }
    }
  }
  return data;
};

export const caseConvertExperiment = (experiment: Experiment | any) => {
  experiment = toCamelJSON(experiment);
  if (experiment.setting) {
    experiment.setting = toCamelJSON(experiment.setting);
  }
  return experiment;
};

export const caseConvertInterview = (interview: Interview | any) => {
  interview = toCamelJSON(interview);
  if (interview.avatar) {
    interview.avatar = toCamelJSON(interview.avatar);
  }
  if (interview.preQuestionAnswer) {
    interview.preQuestionAnswer = toCamelJSON(interview.preQuestionAnswer);
  }
  if (interview.postQuestionAnswer) {
    interview.postQuestionAnswer = toCamelJSON(interview.postQuestionAnswer);
  }
  return interview;
};

export const caseConvertUnlockPageResponse = (response: any) => {
  return toCamelJSON(response);
};

export const caseConvertExperimentResponse = (
  response: ExperimentResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.experiment) {
    data.experiment = caseConvertExperiment(data.experiment);
  }
  if (data.interviews) {
    for (let i = 0; i < data.interviews.length; i++) {
      data.interviews[i] = caseConvertInterview(data.interviews[i]);
    }
  }
  return data;
};

export const caseConvertSubmitExperimentResponse = (
  response: SubmitExperimentResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.experiment) {
    data.experiment = caseConvertExperiment(data.experiment);
  }
  return data;
};

export const caseConvertSubmitInterviewResponse = (
  response: SubmitInterviewResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.interview) {
    data.interview = caseConvertInterview(data.interview);
  }
  return data;
};

export const caseConvertInterviewQuestion = (
  interviewQuestion: InterviewQuestion | any
) => {
  return toCamelJSON(interviewQuestion);
};

export const caseConvertInterviewAnswer = (
  interviewAnswer: InterviewAnswer | any
) => {
  return toCamelJSON(interviewAnswer);
};

export const caseConvertInterviewQuestionAnswers = (
  interviewQuestionAnswer: InterviewQuestionAnswer | any
) => {
  return toCamelJSON(interviewQuestionAnswer);
};

export const caseConvertAddInterviewQuestionResponse = (
  response: AddInterviewQuestionResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.interviewQuestion) {
    data.interviewQuestion = caseConvertInterviewQuestion(
      data.interviewQuestion
    );
  }
  return data;
};

export const caseConvertAddInterviewAnswerResponse = (
  response: AddInterviewAnswerResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.interviewAnswer) {
    data.interviewAnswer = caseConvertInterviewAnswer(data.interviewAnswer);
  }
  return data;
};

export const caseConvertGetInterviewQuestionAnswerListResponse = (
  response: GetInterviewQuestionAnswerListResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.interviewQuestionAnswers) {
    for (let i = 0; i < data.interviewQuestionAnswers.length; i++) {
      data.interviewQuestionAnswers[i] = caseConvertInterviewQuestionAnswers(
        data.interviewQuestionAnswers[i]
      );
    }
  }
  return data;
};

export const caseConvertAvatarVideoListResponse = (
  response: AvatarVideoListResponse | any
) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.avatarVideos) {
    for (let i = 0; i < data.avatarVideos.length; i++) {
      data.avatarVideos[i] = caseConvertAvatarVideoResponse(
        data.avatarVideos[i]
      );
    }
  }
  return data;
};

export const caseConvertAvatarVideoResponse = (
  response: AvatarVideoResponse | any
) => {
  return toCamelJSON(response);
};

export const caseConvertAvatarKeywordListResponse = (
  response: AvatarKeywordListResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertInterviewFeedback = (
  interviewFeedback: InterviewFeedback | any
) => {
  return toCamelJSON(interviewFeedback);
};

export const caseConvertGetInterviewFeedbacksResponse = (
  response: GetInterviewFeedbacksResponse | any
) => {
  const data = toCamelJSON(response);
  if (data.interviewFeedbackP1) {
    data.interviewFeedbackP1 = caseConvertInterviewFeedback(
      data.interviewFeedbackP1
    );
  }
  if (data.interviewFeedbackP2) {
    data.interviewFeedbackP2 = caseConvertInterviewFeedback(
      data.interviewFeedbackP2
    );
  }
  if (data.interviewFeedbackN1) {
    data.interviewFeedbackN1 = caseConvertInterviewFeedback(
      data.interviewFeedbackN1
    );
  }
  if (data.interviewFeedbackN2) {
    data.interviewFeedbackN2 = caseConvertInterviewFeedback(
      data.interviewFeedbackN2
    );
  }
  return data;
};

export const caseConvertSubmitInterviewFeedbackResponse = (
  response: SubmitInterviewFeedbackResponse | any
) => {
  const data = toCamelJSON(response);
  if (data.interviewFeedbackP1) {
    data.interviewFeedbackP1 = caseConvertInterviewFeedback(
      data.interviewFeedbackP1
    );
  }
  if (data.interviewFeedbackP2) {
    data.interviewFeedbackP2 = caseConvertInterviewFeedback(
      data.interviewFeedbackP2
    );
  }
  if (data.interviewFeedbackN1) {
    data.interviewFeedbackN1 = caseConvertInterviewFeedback(
      data.interviewFeedbackN1
    );
  }
  if (data.interviewFeedbackN2) {
    data.interviewFeedbackN2 = caseConvertInterviewFeedback(
      data.interviewFeedbackN2
    );
  }
  return data;
};

export const caseConvertGetTrainingResultResponse = (
  response: GetTrainingResultResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

// Avatar AI API

export const caseConvertAvatar = (avatar: Avatar | any) => {
  return toCamelJSON(avatar);
};

export const caseConvertAvatarResponse = (response: AvatarResponse | any) => {
  // Convert to camelCase
  const data = toCamelJSON(response);
  if (data.avatar) {
    data.avatar = caseConvertAvatarResponse(data.avatar);
  }
  return data;
};

export const caseConvertGetVideoResponse = (
  response: GetVideoResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertGetVideoIdleResponse = (
  response: GetVideoIdleResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertGetVideoExampleResponse = (
  response: GetVideoExampleResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertResponsePredictionResponse = (
  response: ResponsePredictionResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertSpeechToTextResponse = (
  response: SpeechToTextResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertPredictionResponse = (
  response: PredictionResponse | any
) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

// Avatar training SIO response
export const caseConvertPageConfigResponse = (response: object) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertOperatorResponse = (response: object) => {
  // Convert to camelCase
  return toCamelJSON(response);
};

export const caseConvertOperatorFeedbacks = (response: object) => {
  // Convert to camelCase
  return toCamelJSON(response);
};
