import React from "react";
import { FieldRenderProps } from "react-final-form";

import styles from "./RangeInput.module.css";

type Props = FieldRenderProps<string, any>;

const RangeInput: React.FC<Props> = ({ input, meta, ...rest }: Props) => (
  <div className={styles.inputWrapper}>
    <div className={styles.inputLabel}>
      {rest.min}
      {rest.unit ?? ""}
    </div>
    <input
      className={
        (meta.error || meta.submitError) && meta.touched
          ? styles.errorInputField
          : styles.inputField
      }
      type="range"
      {...input}
      {...rest}
    />
    <div className={styles.inputLabel}>
      {rest.max}
      {rest.unit ?? ""}
    </div>
    {(meta.error || meta.submitError) && meta.touched && (
      <span className={styles.errorText}>{meta.error || meta.submitError}</span>
    )}
  </div>
);

export { RangeInput };
