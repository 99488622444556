import {
  AddInterviewAnswerLogRequest,
  AddInterviewQuestionLogRequest,
  CreateExperimentProps,
  CreateExperimentRequest,
  CreateInterviewAnswerProps,
  CreateInterviewFeedbackProps,
  CreateInterviewQuestionProps,
  InterviewQuestion,
  InterviewQuestionAnswer,
  PredictionRequest,
  Profile,
  ResponsePredictionRequest,
  ResponsePredictionResponse,
  SpeechToTextResponse,
  SubmitAgreementRequest,
  SubmitDebriefingRequest,
  SubmitInterviewFeedbacksRequest,
  SubmitPostQuestionAnswerRequest,
  SubmitPreQuestionAnswerRequest,
  SubmitProfileRequest,
  UpdateSettingProps,
  UpdateSettingRequest,
} from "../types";
import { toSnakeJSON } from "./caseConverter";

export const caseConvertUpdateSettingProps = (setting: UpdateSettingProps) => {
  return toSnakeJSON(setting);
};

export const caseConvertUpdateSettingRequest = (
  request: UpdateSettingRequest
) => {
  if (request.setting) {
    request.setting = caseConvertUpdateSettingProps(request.setting);
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConvertCreateExperimentProps = (
  props: CreateExperimentProps
) => {
  return toSnakeJSON(props);
};

export const caseConvertCreateExperimentRequest = (
  request: CreateExperimentRequest
) => {
  if (request.newExperiment) {
    request.newExperiment = caseConvertCreateExperimentProps(
      request.newExperiment
    );
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConvertSubmitAgreementRequest = (
  request: SubmitAgreementRequest
) => {
  return toSnakeJSON(request);
};

export const caseConvertProfile = (profile: Profile) => {
  return toSnakeJSON(profile);
};

export const caseConvertSubmitProfileRequest = (
  request: SubmitProfileRequest
) => {
  if (request.profile) {
    request.profile = caseConvertProfile(request.profile);
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConvertSubmitPreQuestionAnswerRequest = (
  request: SubmitPreQuestionAnswerRequest
) => {
  return toSnakeJSON(request);
};

export const caseConvertCreateInterviewQuestionProps = (
  props: CreateInterviewQuestionProps
) => {
  return toSnakeJSON(props);
};

export const caseConvertAddInterviewQuestionLogRequest = (
  request: AddInterviewQuestionLogRequest
) => {
  if (request.newInterviewQuestion) {
    request.newInterviewQuestion = caseConvertCreateInterviewQuestionProps(
      request.newInterviewQuestion
    );
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConvertCreateInterviewAnswerProps = (
  props: CreateInterviewAnswerProps
) => {
  return toSnakeJSON(props);
};

export const caseConvertAddInterviewAnswerLogRequest = (
  request: AddInterviewAnswerLogRequest
) => {
  if (request.newInterviewAnswer) {
    request.newInterviewAnswer = caseConvertCreateInterviewAnswerProps(
      request.newInterviewAnswer
    );
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConvertSubmitPostQuestionAnswerRequest = (
  request: SubmitPostQuestionAnswerRequest
) => {
  return toSnakeJSON(request);
};

export const caseConvertCreateInterviewFeedbackProps = (
  props: CreateInterviewFeedbackProps
) => {
  return toSnakeJSON(props);
};

export const caseConverSubmitInterviewFeedbacksRequest = (
  request: SubmitInterviewFeedbacksRequest
) => {
  if (request.newInterviewFeedbackP1) {
    request.newInterviewFeedbackP1 = caseConvertCreateInterviewFeedbackProps(
      request.newInterviewFeedbackP1
    );
  }
  if (request.newInterviewFeedbackP2) {
    request.newInterviewFeedbackP2 = caseConvertCreateInterviewFeedbackProps(
      request.newInterviewFeedbackP2
    );
  }
  if (request.newInterviewFeedbackN1) {
    request.newInterviewFeedbackN1 = caseConvertCreateInterviewFeedbackProps(
      request.newInterviewFeedbackN1
    );
  }
  if (request.newInterviewFeedbackN2) {
    request.newInterviewFeedbackN2 = caseConvertCreateInterviewFeedbackProps(
      request.newInterviewFeedbackN2
    );
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConverSubmitDebriefingRequest = (
  request: SubmitDebriefingRequest
) => {
  return toSnakeJSON(request);
};

// Avatar AI API

export const caseConvertResponsePredictionResponseRequest = (
  request: ResponsePredictionResponse
) => {
  return toSnakeJSON(request);
};

export const caseConvertResponsePredictionRequest = (
  request: ResponsePredictionRequest
) => {
  if (request.previousResponse) {
    request.previousResponse = caseConvertResponsePredictionResponseRequest(
      request.previousResponse
    );
  }
  const data = toSnakeJSON(request);
  return data;
};

export const caseConvertPredictionRequest = (request: PredictionRequest) => {
  return toSnakeJSON(request);
};

// Avatar training SIO
export const caseConvertPageConfigRequest = (request: object) => {
  return toSnakeJSON(request);
};

export const caseConvertSpeechToTextResultRequest = (
  request: SpeechToTextResponse
) => {
  return toSnakeJSON(request);
};

export const caseConvertOperatorResponseRequest = (request: object) => {
  return toSnakeJSON(request);
};

export const caseConvertOperatorFeedbackRequest = (request: object) => {
  return toSnakeJSON(request);
};

export const caseConvertInterviewQuestionAnswerRequest = (
  request: InterviewQuestionAnswer
) => {
  return toSnakeJSON(request);
};

export const caseConvertInterviewQuestionRequest = (
  request: InterviewQuestion
) => {
  return toSnakeJSON(request);
};
