import React from "react";
import { FieldRenderProps } from "react-final-form";

import styles from "./CheckboxInput.module.css";

type Props = FieldRenderProps<string, any>;

const CheckboxInput: React.FC<Props> = ({ input, meta, ...rest }: Props) => (
  <div className={styles.inputWrapper}>
    <input
      className={
        (meta.error || meta.submitError) && meta.touched
          ? styles.errorInputField
          : styles.inputField
      }
      type="checkbox"
      {...input}
      {...rest}
    />
    {(meta.error || meta.submitError) && meta.touched && (
      <span className={styles.errorText}>{meta.error || meta.submitError}</span>
    )}
  </div>
);

export { CheckboxInput };
