import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Head from "next/head";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "../components/layout/Footer";
import { Header as MyHeader } from "../components/layout";
import styles from "../styles/app.module.css";
import "../lib/i18next";
import { unlockPage } from "../lib/avatarTrainingAIAPI/fetch";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const [locked, setLocked] = useState(1);

  useEffect(() => {
    if (router.locale === "ja" && locked) {
      let input: string | null = "";
      input = prompt(t("startPage:inputPasscode", { lng: router.locale }));

      const authorize = async (input: string) => {
        const response = await unlockPage(input);
        response.data.authorized ? setLocked(0) : setLocked((val) => val + 1);
      };

      input ? authorize(input) : setLocked((val) => val + 1);
    }
  }, [locked, t, router.locale]);

  return router.locale === "ja" && locked ? (
    <p>Loading...</p>
  ) : (
    <div className={styles.container}>
      <Head>
        <title>Avatar Training</title>
        <meta name="description" content="Avatar Training App" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <MyHeader />
      <main className={styles.main}>
        <Component {...pageProps} />
      </main>
      <footer className={styles.footer}>
        <Footer />
      </footer>
    </div>
  );
}

export default MyApp;
