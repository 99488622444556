import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <p className={styles.footerText}>{`${new Date().getFullYear()} - ${t(
        "common:avatarTraining"
      )}`}</p>
    </div>
  );
};

export { Footer };
