import React, { ChangeEvent, ChangeEventHandler } from "react";
import { FieldRenderProps } from "react-final-form";

import styles from "./SelectInputBlack.module.css";

type Props = FieldRenderProps<string, any>;

const SelectInputBlack: React.FC<Props> = ({ input, meta, ...rest }: Props) => (
  <div className={styles.inputWrapper}>
    <select
      className={
        (meta.error || meta.submitError) && meta.touched
          ? styles.errorInputField
          : styles.inputField
      }
      {...input}
      {...rest}
    />
    {(meta.error || meta.submitError) && meta.touched && (
      <span className={styles.errorText}>{meta.error || meta.submitError}</span>
    )}
  </div>
);

interface NonFookProps {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  value: number;
  error?: string;
}

const NonHookFormSelectInputBlack: React.FC<NonFookProps> = ({
  error,
  ...rest
}: NonFookProps) => (
  <div className={styles.inputWrapper}>
    <select
      className={error ? styles.errorInputField : styles.inputField}
      {...rest}
    />
    {error && <span className={styles.errorText}>{error}</span>}
  </div>
);

export { SelectInputBlack, NonHookFormSelectInputBlack };
