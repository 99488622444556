import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { SelectInputBlack } from "../../ui";

import styles from "./Header.module.css";
import { useEffect } from "react";

const Header = () => {
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (router.locale && router.locale === "default") {
      router.push(
        {
          pathname: router.pathname,
        },
        undefined,
        {
          locale: "en",
        }
      );
    } else {
      router.locale && i18n.changeLanguage(router.locale);
    }
  }, [router.locale]);

  return (
    <nav className={styles.headerNav}>
      <ul className={styles.menu}>
        <li
          className={
            router.pathname === "/" ? styles.activeMenuItem : styles.menuItem
          }
        >
          <Link href="/">{t("common:home")}</Link>
        </li>
        {(router.pathname === "/" ||
          router.pathname === "/i40g-efaw-eaio-19ei") && (
          <li className={styles.languageSelectorItem}>
            <Form
              onSubmit={() => {}}
              initialValues={{ language: i18n.language }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className={styles.fieldWrapper}>
                    <Field<string>
                      id="language"
                      name="language"
                      component={SelectInputBlack}
                    >
                      <option value="ja">{t("startPage:ja")}</option>
                      <option value="en">{t("startPage:en")}</option>
                      <option value="zh">{t("startPage:zh")}</option>
                      <option value="et">{t("startPage:et")}</option>
                      <option value="lt">{t("startPage:lt")}</option>
                      <option value="fi">{t("startPage:fi")}</option>
                    </Field>
                    <OnChange name="language">
                      {(value, previous) => {
                        console.log(value);
                        router.push(
                          {
                            pathname: router.pathname,
                          },
                          undefined,
                          {
                            locale: value,
                          }
                        );
                        // i18n.changeLanguage(value);
                      }}
                    </OnChange>
                  </div>
                </form>
              )}
            />
          </li>
        )}
        {/* <li
          className={
            router.pathname == "/log" ? styles.activeMenuItem : styles.menuItem
          }
        >
          <Link href="/log">{t("common:log")}</Link>
        </li>
        <li
          className={
            router.pathname == "/setting"
              ? styles.activeMenuItem
              : styles.menuItem
          }
        >
          <Link href="/setting">{t("common:setting")}</Link>
        </li>
        <li
          className={
            router.pathname == "/contact"
              ? styles.activeMenuItem
              : styles.menuItem
          }
        >
          <Link href="/contact">{t("common:contact")}</Link>
        </li>
        <li
          className={
            router.pathname == "/admin"
              ? styles.activeMenuItem
              : styles.menuItem
          }
        >
          <Link href="/admin">{t("common:admin")}</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export { Header };
