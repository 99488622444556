import React from "react";
import { FieldRenderProps } from "react-final-form";

import styles from "./RadioInput.module.css";

type Props = FieldRenderProps<string, any>;

const RadioInput: React.FC<Props> = ({
  input,
  meta,
  label,
  ...rest
}: Props) => (
  <div className={styles.inputWrapper}>
    <label>
      <input
        className={
          (meta.error || meta.submitError) && meta.touched
            ? styles.errorInputField
            : styles.inputField
        }
        type="radio"
        {...input}
        {...rest}
      />
      {label}
    </label>
    {(meta.error || meta.submitError) && meta.touched && (
      <span className={styles.errorText}>{meta.error || meta.submitError}</span>
    )}
  </div>
);

export { RadioInput };
