import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationCommonJA from "./locales/ja/common.json";
import translationInterviewJA from "./locales/ja/interview.json";
import translationStartPageJA from "./locales/ja/startPage.json";
import translationAgreementPageJA from "./locales/ja/agreementPage.json";
import translationProfilePageJA from "./locales/ja/profilePage.json";
import translationGuidelinePageJA from "./locales/ja/guidelinePage.json";
import translationExamplePageJA from "./locales/ja/examplePage.json";
import translationVideoExamplePageJA from "./locales/ja/videoExamplePage.json";
import translationPreQuestionPageJA from "./locales/ja/preQuestionPage.json";
import translationPreTeachingPageJA from "./locales/ja/preTeachingPage.json";
import translationPostQuestionPageJA from "./locales/ja/postQuestionPage.json";
import translationConclusionPageJA from "./locales/ja/conclusionPage.json";
import translationFeedbackPageJA from "./locales/ja/feedbackPage.json";
import translationDebriefingPageJA from "./locales/ja/debriefingPage.json";
import translationTrainingResultPageJA from "./locales/ja/trainingResultPage.json";
import translationFinishPageJA from "./locales/ja/finishPage.json";
import translationExperimentListJA from "./locales/ja/experimentList.json";
import translationInterviewTutorialJA from "./locales/ja/interviewTutorial.json";

import translationCommonEN from "./locales/en/common.json";
import translationInterviewEN from "./locales/en/interview.json";
import translationStartPageEN from "./locales/en/startPage.json";
import translationAgreementPageEN from "./locales/en/agreementPage.json";
import translationProfilePageEN from "./locales/en/profilePage.json";
import translationGuidelinePageEN from "./locales/en/guidelinePage.json";
import translationExamplePageEN from "./locales/en/examplePage.json";
import translationVideoExamplePageEN from "./locales/en/videoExamplePage.json";
import translationPreQuestionPageEN from "./locales/en/preQuestionPage.json";
import translationPostQuestionPageEN from "./locales/en/postQuestionPage.json";
import translationFeedbackPageEN from "./locales/en/feedbackPage.json";
import translationConclusionPageEN from "./locales/en/conclusionPage.json";
import translationTrainingResultPageEN from "./locales/en/trainingResultPage.json";
import translationFinishPageEN from "./locales/en/finishPage.json";
import translationInterviewTutorialEN from "./locales/en/interviewTutorial.json";
import translationExperimentListEN from "./locales/en/experimentList.json";

import translationCommonZH from "./locales/zh/common.json";
import translationInterviewZH from "./locales/zh/interview.json";
import translationStartPageZH from "./locales/zh/startPage.json";
import translationAgreementPageZH from "./locales/zh/agreementPage.json";
import translationProfilePageZH from "./locales/zh/profilePage.json";
import translationGuidelinePageZH from "./locales/zh/guidelinePage.json";
import translationVideoExamplePageZH from "./locales/zh/videoExamplePage.json";
import translationPreQuestionPageZH from "./locales/zh/preQuestionPage.json";
import translationPostQuestionPageZH from "./locales/zh/postQuestionPage.json";
import translationFinishPageZH from "./locales/zh/finishPage.json";
import translationInterviewTutorialZH from "./locales/zh/interviewTutorial.json";
import translationExperimentListZH from "./locales/zh/experimentList.json";

import translationCommonET from "./locales/et/common.json";
import translationInterviewET from "./locales/et/interview.json";
import translationStartPageET from "./locales/et/startPage.json";
import translationAgreementPageET from "./locales/et/agreementPage.json";
import translationProfilePageET from "./locales/et/profilePage.json";
import translationGuidelinePageET from "./locales/et/guidelinePage.json";
import translationVideoExamplePageET from "./locales/et/videoExamplePage.json";
import translationPreQuestionPageET from "./locales/et/preQuestionPage.json";
import translationPostQuestionPageET from "./locales/et/postQuestionPage.json";
import translationFinishPageET from "./locales/et/finishPage.json";
import translationInterviewTutorialET from "./locales/et/interviewTutorial.json";
import translationExperimentListET from "./locales/et/experimentList.json";

import translationCommonLT from "./locales/lt/common.json";
import translationInterviewLT from "./locales/lt/interview.json";
import translationStartPageLT from "./locales/lt/startPage.json";
import translationAgreementPageLT from "./locales/lt/agreementPage.json";
import translationProfilePageLT from "./locales/lt/profilePage.json";
import translationGuidelinePageLT from "./locales/lt/guidelinePage.json";
import translationPreQuestionPageLT from "./locales/lt/preQuestionPage.json";
import translationPostQuestionPageLT from "./locales/lt/postQuestionPage.json";
import translationDebriefingPageLT from "./locales/lt/debriefingPage.json";
import translationTrainingResultPageLT from "./locales/lt/trainingResultPage.json";
import translationFinishPageLT from "./locales/lt/finishPage.json";
import translationInterviewTutorialLT from "./locales/lt/interviewTutorial.json";
import translationExperimentListLT from "./locales/lt/experimentList.json";

import translationCommonFI from "./locales/fi/common.json";
import translationInterviewFI from "./locales/fi/interview.json";
import translationStartPageFI from "./locales/fi/startPage.json";
import translationAgreementPageFI from "./locales/fi/agreementPage.json";
import translationProfilePageFI from "./locales/fi/profilePage.json";
import translationGuidelinePageFI from "./locales/fi/guidelinePage.json";
import translationExamplePageFI from "./locales/fi/examplePage.json";
import translationVideoExamplePageFI from "./locales/fi/videoExamplePage.json";
import translationPreQuestionPageFI from "./locales/fi/preQuestionPage.json";
import translationPostQuestionPageFI from "./locales/fi/postQuestionPage.json";
import translationFeedbackPageFI from "./locales/fi/feedbackPage.json";
import translationConclusionPageFI from "./locales/fi/conclusionPage.json";
import translationTrainingResultPageFI from "./locales/fi/trainingResultPage.json";
import translationFinishPageFI from "./locales/fi/finishPage.json";
import translationInterviewTutorialFI from "./locales/fi/interviewTutorial.json";
import translationExperimentListFI from "./locales/fi/experimentList.json";

const resources = {
  ja: {
    common: translationCommonJA,
    interview: translationInterviewJA,
    startPage: translationStartPageJA,
    agreement: translationAgreementPageJA,
    profile: translationProfilePageJA,
    guideline: translationGuidelinePageJA,
    example: translationExamplePageJA,
    videoExample: translationVideoExamplePageJA,
    preQuestion: translationPreQuestionPageJA,
    preTeaching: translationPreTeachingPageJA,
    postQuestion: translationPostQuestionPageJA,
    conclusion: translationConclusionPageJA,
    feedback: translationFeedbackPageJA,
    debriefing: translationDebriefingPageJA,
    trainingResult: translationTrainingResultPageJA,
    finishPage: translationFinishPageJA,
    experimentList: translationExperimentListJA,
    interviewTutorial: translationInterviewTutorialJA,
  },
  en: {
    common: translationCommonEN,
    interview: translationInterviewEN,
    startPage: translationStartPageEN,
    agreement: translationAgreementPageEN,
    profile: translationProfilePageEN,
    guideline: translationGuidelinePageEN,
    example: translationExamplePageEN,
    videoExample: translationVideoExamplePageEN,
    preQuestion: translationPreQuestionPageEN,
    postQuestion: translationPostQuestionPageEN,
    conclusion: translationConclusionPageEN,
    feedback: translationFeedbackPageEN,
    trainingResult: translationTrainingResultPageEN,
    finishPage: translationFinishPageEN,
    interviewTutorial: translationInterviewTutorialEN,
    experimentList: translationExperimentListEN,
  },
  zh: {
    common: translationCommonZH,
    interview: translationInterviewZH,
    startPage: translationStartPageZH,
    agreement: translationAgreementPageZH,
    profile: translationProfilePageZH,
    guideline: translationGuidelinePageZH,
    videoExample: translationVideoExamplePageZH,
    preQuestion: translationPreQuestionPageZH,
    postQuestion: translationPostQuestionPageZH,
    finishPage: translationFinishPageZH,
    interviewTutorial: translationInterviewTutorialZH,
    experimentList: translationExperimentListZH,
  },
  et: {
    common: translationCommonET,
    interview: translationInterviewET,
    startPage: translationStartPageET,
    agreement: translationAgreementPageET,
    profile: translationProfilePageET,
    guideline: translationGuidelinePageET,
    videoExample: translationVideoExamplePageET,
    preQuestion: translationPreQuestionPageET,
    postQuestion: translationPostQuestionPageET,
    finishPage: translationFinishPageET,
    interviewTutorial: translationInterviewTutorialET,
    experimentList: translationExperimentListET,
  },
  lt: {
    common: translationCommonLT,
    interview: translationInterviewLT,
    startPage: translationStartPageLT,
    agreement: translationAgreementPageLT,
    profile: translationProfilePageLT,
    guideline: translationGuidelinePageLT,
    preQuestion: translationPreQuestionPageLT,
    postQuestion: translationPostQuestionPageLT,
    debriefing: translationDebriefingPageLT,
    trainingResult: translationTrainingResultPageLT,
    finishPage: translationFinishPageLT,
    interviewTutorial: translationInterviewTutorialLT,
    experimentList: translationExperimentListLT,
  },
  fi: {
    common: translationCommonFI,
    interview: translationInterviewFI,
    startPage: translationStartPageFI,
    agreement: translationAgreementPageFI,
    profile: translationProfilePageFI,
    guideline: translationGuidelinePageFI,
    example: translationExamplePageFI,
    videoExample: translationVideoExamplePageFI,
    preQuestion: translationPreQuestionPageFI,
    postQuestion: translationPostQuestionPageFI,
    conclusion: translationConclusionPageFI,
    feedback: translationFeedbackPageFI,
    trainingResult: translationTrainingResultPageFI,
    finishPage: translationFinishPageFI,
    interviewTutorial: translationInterviewTutorialFI,
    experimentList: translationExperimentListFI,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    defaultNS: "common",
  });

export { i18n };
