import _ from "lodash";

/**
 * Convert Json keys to snake_case
 * Return the same object as input to prevent caller component to be rerendered.
 *
 * @param body
 * @returns body
 *
 */
export const toSnakeJSON = (body: { [key: string | number]: any }): any => {
  const keys = Object.keys(body);
  for (let key of keys) {
    const tmp = body[key];
    delete body[key];
    body[_.snakeCase(key)] = tmp;
  }
  return body;
};

/**
 * Convert Json keys to camelCase
 * Return the same object as input to prevent caller component to be rerendered.
 *
 * @param body
 * @returns body
 *
 */
export const toCamelJSON = (body: { [key: string | number]: any }): any => {
  const keys = Object.keys(body);
  for (let key of keys) {
    const tmp = body[key];
    delete body[key];
    body[_.camelCase(key)] = tmp;
  }
  return body;
};
